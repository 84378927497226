export enum EnumMainPageRoute {
  Auth = "auth",
  Dashboard = "dashboard",
  DataWarehouse = "data-ware-house",
  DataPipeline = "data-pipeline",
  SmartQuery = "smart-query",
  SmartSearch = "smart-search",
  DataComparator = "data-comparator",

  Chatbot = 'chatbot',
  ContentHub = 'content-hub',
  UserGroup = "user-group",
  AccessControl = "access-control",
  Account = "account",
  AdminSetting = "admin-setting",
  Notification = "notification",
  Error = "error",
  LLMModel = "llm-model",
}

export enum EnumAuthPageRoute {
  Login = "login"
}

export enum EnumPipelinePageRoute {
  AccessRight = "access-right",
  PipelineFlow = "pipeline-flow",
  PipelineFlowEdit = "pipeline-flow-edit",
  RecycleBin = "recycle-bin",
  Edit = "edit",
  Console = "console",
  Preview = "preview",
  History = "history"
}

export enum EnumDataWarehousePageRoute {
  RecycleBin = "recycle-bin",
  DataView = "data-view",
  QuerySample = "query-sample",
  DataEntity = "data-entity",
  FileUpload = "file-upload",
  DataSchema = "data-schema",
  History = "history",
  AccessRight = "access-right",
  EntityArchive = "entity-archive",
  EntityExport = "entity-export",
  EntityRelation = "entity-relation",
  EntityRelationCreate = "entity-relation-create",
  EntityRelationEdit = "entity-relation-edit",
  ApiFetching = "api-fetching"
}

export enum EnumSmartQueryPageRoute {
  SmartQueryEditing = "smart-query-editing",
  SmartQueryEditingVersion2 = "smart-query-editing-version2",
  SmartQueryView = "smart-query-view",
  AccessRight = "access-right"
}

export enum EnumUserGroupPageRoute {
  GroupDetailsManagement = "group-details-management",
  UserDetailsManagement = "user-details-management",
  AccessRightManagement = "access-right-management",
  CreateUser = "create-user"
}

export enum EnumAccountPageRoute {
  MyProfile = "my-profile"
}

export enum EnumContentHubPageRoute {
  RecycleBin = "recycle-bin",
  Edit = "edit",
}

export enum EnumChatbotPageRoute{
  RecycleBin = "recycle-bin",
  Edit = "edit",
  ChatInterface = "chat-interface",
  Statistic = "statistic"
}

export enum EnumDataComparatorPageRoute{
  RecycleBin= "recycle-bin",
  Edit = "edit",
}
