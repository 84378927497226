import { ScrollingModule } from "@angular/cdk/scrolling";
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatDividerModule } from "@angular/material/divider";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatInputModule } from "@angular/material/input";
import { MatSelectModule } from "@angular/material/select";
import { MatSlideToggleModule } from "@angular/material/slide-toggle";
import { NgbModule } from '@ng-bootstrap/ng-bootstrap';
import { InlineSVGModule } from "ng-inline-svg";
import { CustomFormsModule } from 'ng2-validation';
import { AlertBannerComponent } from "./components/alert-banner/alert-banner.component";
import { FeatureImageComponent } from "./components/feature-image/feature-image.component";
import { JquerySelect2AdvancedComponent } from "./components/jquery-select2-advanced/jquery-select2-advanced.component";
import { JQuerySelect2Component } from './components/jquery-select2/jquery-selet2.component';
import { LogicQuerySetterComponent } from "./components/logic-query-setter/logic-query-setter.component";
import { NgbDateRangeComponent } from "./components/ngb-date-range/ngb-date-range.component";
import {
  NoSchemaLogicQuerySetterComponent
} from "./components/no_schema_logic-query-setter/no-schema-logic-query-setter.component";
import { PopoverInfoComponent } from "./components/popover/popover-info.component";
import { SelectedColumnComponent } from "./components/selected-column/selected-column.component";
import { BtnConfirmationDirective } from "./directives/btn-conconfirm/btn-confirmation.directive";
import { FadeInDirective } from "./directives/fade-in/fade-in.directive";
import { HrefPreventDefaultDirective } from "./directives/href-prevent-default/href-prevent-default.directive";
import { LastDirective } from "./directives/is-last/is-last.directive";
import { ShadowOnHoverDirective } from "./directives/shadow-on-hover.directive";
import { SlimScrollDirective } from "./directives/slim-scroll/slim-scroll.directive";
import { UnwrapTagDirective } from "./directives/unwrap-tag/unwrap-tag.directive";
import { SetCustomFieldModalComponent } from "./modals/set-custom-field-modal/set-custom-field-modal.component";
import { SetDateFormatModalComponent } from "./modals/set-date-format-modal/set-date-format-modal.component";
import { SetFilemaskModalComponent } from "./modals/set-filemask-modal/set-filemask-modal.component";
import { SetScheduleModalComponent } from "./modals/set-schedule-modal/set-schedule-modal.component";
import { EnumToArrayOfObjectValuePipe } from "./pipes/enum-to-array-of-object-value.pipe";
import { FileSizePipe } from "./pipes/file-size.pipe";
import { JsonHighlightPipe } from "./pipes/json-highlight.pipe";
import { OperatorTransformPipe } from "./pipes/operator-transform.pipe";
import { RemoveDollarPipe } from "./pipes/remove-dollar.pipe";
import { TextcaseConvertPipe } from "./pipes/textcase-convert.pipe";
import { TimeDurationPipe } from "./pipes/time-duration.pipe";
import { UserRolePipe } from "./pipes/user-role.pipe";
import { ValidationErrMsgPipe } from "./pipes/validation-error-msg.pipe";
import { ValidatorsModule } from "./validators.module";
import {DatepickerPopupComponent} from "./components/datapicker-popup/datepicker-popup.component";

@NgModule({
  imports: [
    FormsModule,
    NgbModule,
    CommonModule,
    CustomFormsModule,
    MatFormFieldModule,
    MatAutocompleteModule,
    MatInputModule,
    ReactiveFormsModule,
    MatDividerModule,
    InlineSVGModule,
    MatSlideToggleModule,
    MatSelectModule,
    MatCheckboxModule,
    ScrollingModule,
    ValidatorsModule,
  ],
  declarations: [
    JQuerySelect2Component,
    JquerySelect2AdvancedComponent,
    LogicQuerySetterComponent,
    NoSchemaLogicQuerySetterComponent,
    NgbDateRangeComponent,
    SetScheduleModalComponent,
    FeatureImageComponent,
    SetScheduleModalComponent,
    SetFilemaskModalComponent,
    SetDateFormatModalComponent,
    SetCustomFieldModalComponent,
    PopoverInfoComponent,
    SelectedColumnComponent,
    AlertBannerComponent,
    // directive
    BtnConfirmationDirective,
    UnwrapTagDirective,
    LastDirective,
    SlimScrollDirective,
    HrefPreventDefaultDirective,
    FadeInDirective,
    // pipe
    ValidationErrMsgPipe,
    FileSizePipe,
    UserRolePipe,
    TimeDurationPipe,
    TextcaseConvertPipe,
    JsonHighlightPipe,
    EnumToArrayOfObjectValuePipe,
    RemoveDollarPipe,
    OperatorTransformPipe,
    ShadowOnHoverDirective,
    DatepickerPopupComponent,
  ],
  exports: [
    NgbModule,
    CommonModule,
    JQuerySelect2Component,
    JquerySelect2AdvancedComponent,
    FeatureImageComponent,
    PopoverInfoComponent,
    LogicQuerySetterComponent,
    NoSchemaLogicQuerySetterComponent,
    NgbDateRangeComponent,
    SelectedColumnComponent,
    AlertBannerComponent,
    // directive
    BtnConfirmationDirective,
    UnwrapTagDirective,
    LastDirective,
    SlimScrollDirective,
    HrefPreventDefaultDirective,
    FadeInDirective,
    // pipe
    ValidationErrMsgPipe,
    FileSizePipe,
    UserRolePipe,
    TimeDurationPipe,
    TextcaseConvertPipe,
    JsonHighlightPipe,
    EnumToArrayOfObjectValuePipe,
    RemoveDollarPipe,
    OperatorTransformPipe,
    ShadowOnHoverDirective,
    DatepickerPopupComponent,
  ]

})
export class SharedModule { }
